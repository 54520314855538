<template>
  <v-container>
    <v-card color="rgba(255,255,255,1)" elevation="2" class="cardContainer">
      <v-container class="d-flex flex-column">
        <v-container class="workspaceImgContainer">
          <v-img class="workspaceImg" contain src="../assets/aboutMe/workspace1.png"/>
          <v-img class="workspaceImg" contain src="../assets/aboutMe/workspace2.png"/>
        </v-container>
        <p class="aboutText">Welcome to our cozy and inviting salon studio! Tucked away in a small, safe, and quiet space our studio features two comfy chairs for a personalized hair experience.</p>
        <p class="aboutText">We know life can be busy, so feel free to bring your work and catch up on emails, or binge-watch your favorite shows on your device-of-choice with earbuds. Please note that our studio is a bit snug for little ones, so we kindly ask that children stay home during your visit.</p>

        <p class="subText"><strong>Payment Options:</strong> For your convenience, we accept all major credit cards and Zelle for services. Gratuities are greatly appreciated and can be left in cash, Venmo, or Zelle directly to your stylist.</p>
        <p class="subText"><strong>Cancellation Policy:</strong> To secure your appointment, we require a credit card on file. Out of respect for your stylists' time, we kindly ask for at least 24 hours notice for cancellations. (48 hrs for services that are 4+ hours) Cancellations within 24 hours will incur a 50% cancellation fee. No-shows will be charged 100% of the service booked.</p>
        <p class="subText"><strong>Consultation Appointments:</strong> To reserve a consultation appointment, a non-refundable $50 deposit is required. If the appointment is canceled or you do not show up, the deposit is forfeited. For hair extension consultations, if you decide to purchase hair for an upcoming hair extension installation, the total cost of the hair must be paid for upfront. On the day of extension installation or color service, the initial $50 deposit will be applied toward the price at check out.</p>
        <p class="subText"><strong>Booking and Contact Info:</strong> For appointments or inquiries, simply text our shop phone at 908-907-6062. Jess, Dannielle, or Lyndsay will respond as soon as possible during regular business hours. (texting is the fastest and best way to reach us) <a href="https://www.vagaro.com/houseoffringe/book-now">Online booking is also available for your convenience</a></p>
        <p class="subText"><strong>Location:</strong> House of Fringe is a small studio located inside of <strong>Haven Salon Studios</strong> in Eatontown. Set back from the Pastosa Ravioli, at <a href="https://maps.app.goo.gl/YohZxsuEQkCcKtzN6" target="_blank">315 RT 35N Suite #122</a>. When you come inside Haven, make a right and we are the third door in on the right.</p>
      </v-container>
    </v-card>

    <v-card color="rgba(255,255,255,1)" elevation="2" class="cardContainer">
      <v-container class="aboutEmployeeCard d-flex flex-column flex-md-row">
        <div class="peronsalImgContainer">
          <v-img class="model d-none d-md-block" contain src="../assets/aboutMe/jess.jpeg" max-width="450px"/>
          <img class="aboutImg d-md-none" src="../assets/aboutMe/jess.jpeg"/>
        </div>
        <div>
          <p class="aboutText">Hi Friend! I'm Jess! an artist, colorist, and extensionist in the industry since 2004. I studied color theory in NYC and am a multi-method certified extension specialist. Sew-in wefts are my favorite, but I love combining and mixing in other methods depending on the unique needs of each individual person. Healthy hair and scalp are always my top priority, ensuring a safe and beautiful transformation every time. I created House of Fringe because I wanted a judgment-free creative space with an elevated guest experience. Hair is a creative outlet for me. Every time you leave my chair I am already brainstorming how to make your next service even better! Never be afraid to tell me if you want something tweaked a bit, I love learning and trying new techniques!</p>
          <p class="subText">
            <strong>Sign:</strong> Aries<br/>
            <strong>Hobbies:</strong> Hikes with my pup, bike rides with my squad, exploring new places and restaurants, craft and sip nights with the girls, family sunday fundays, and being a homebody.<br />
            <a href="https://www.instagram.com/jess_wefts_and_artistry" target="_blank">@jess_wefts_and_artistry</a>
          </p>
        </div>
      </v-container>
    </v-card>

    <v-card color="rgba(255,255,255,1)" elevation="2" class="cardContainer">
      <v-container class="aboutEmployeeCard d-flex flex-column flex-md-row">
        <div class="peronsalImgContainer">
          <v-img class="model d-none d-md-block" contain src="../assets/aboutMe/dannielle.jpeg" max-width="450px"/>
          <img class="aboutImg d-md-none" src="../assets/aboutMe/dannielle.jpeg"/>
        </div>
        <div>
          <p class="aboutText">Meet Dannielle! With over a decade of experience, this wild heart has made it her mission to make your hair dreams a reality. She is a master at dimensional colors, with a passion for blonding. She's always staying up to date on the latest techniques. If you’re looking for that mermaid length and volume, she is also certified in four different extension methods (wefts, I-tips, K-tips, tape-ins). Dannielle crafts a unique experience based on your desires. She gets that achieving your dream color can take some time and that some journeys require multiple steps to keep your hair healthy. Dannielle will work with you to create a personalized plan taking you from your current shade to your dream hair. Step-by-step, with healthy locks all the way.</p>
          <p class="subText">
            <strong>Sign:</strong> Leo<br/>
            <strong>Hobbies:</strong> Exploring new restaurants and breweries, bike rides with her hubby, walking her dog along the waterfront, trips to Key West, concerts, and indulging in her guilty pleasure of unnecessary Amazon purchases.<br />
            <a href="https://www.instagram.com/dannielleuhair" target="_blank">@dannielleuhair</a>
          </p>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'About',

    data: () => ({
    }),
    methods: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .aboutText {
    font-size: 20px;
    margin: 12px;
    text-align: justify;
  }
  .subText {
    margin: 12px;
    text-align: justify;
  }
  .cardContainer {
    margin: 20px;
  }
  .aboutImg {
    width: 100%;
    height: 100;
    max-width: 450px;
    object-fit: cover;
  }
  .workspaceImgContainer {
    display: inline-flex;
    justify-content: space-between;
  }
  .workspaceImg {
    max-width: 48%;
    width: 48%;
    height: fit-content;
  }
  .aboutEmployeeCard {
    align-items: center;
  }
  .peronsalImgContainer {
    display: flex;
    justify-content: center;
  }
</style>